<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    async test() {},
  },
};
</script>

<style lang="scss" scoped></style>
